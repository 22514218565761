<template>
  <k-crud-layout :with-search="false" sheet-color="transparent">
    <template #header>{{ $t('checkout.pageTitle', { orderNr: shoppingCart ? shoppingCart.id : '' }) }}</template>
    <template #view.list>
      <v-tabs v-model="tab"
              background-color="white"
              color="black"
              slider-color="primary">
        <v-tab>{{ $t('checkout.tabs.cartContent') }}</v-tab>
        <v-tab @change="getOrderInformation">{{ $t('checkout.tabs.orderInformation') }}</v-tab>
      </v-tabs>
      <v-row>
        <v-col cols="12" md="8">
          <v-tabs-items class="fill-height" v-model="tab">
            <v-tab-item>
              <v-data-table :headers="crudHeaders"
                            :items="items"
                            :loading="isPendingSubmit"
                            :footer-props="{itemsPerPageOptions: [5,10,15,25],
                   itemsPerPageText: $t('global.$vuetify.dataFooter.itemsPerPageText'),
                   pageText: $t('global.$vuetify.dataFooter.pageText')}">
                <template #item="{item}">
                  <tr class="order-data">
                    <td rowspan="2">
                      <VImg :src="`${item.image}&type=thumbnail`"
                            :lazy-src="`${item.image}&type=lazy`"
                            height="100px"
                            max-width="100px"
                            contain/>
                    </td>
                    <td style="border-bottom: 0;">
                      {{ item.name }}
                    </td>
                    <td style="border-bottom: 0;">
                      {{ item.sku }}
                    </td>

                    <td style="border-bottom: 0">
                      {{item.quantity}}
                    </td>

                    <td style="text-align: right; width: 1%; white-space: nowrap;">
                      <div class="mb-4">
                        <LocalePrice :price="item.localPrice" badge-class="mr-2"/>
                        {{ $n(item.price, 'currency') }}
                      </div>
                    </td>

                    <td rowspan="2" style="text-align: right; width: 1%; white-space: nowrap; position: relative">
                      <div class="mb-4">
                        <LocalePrice :price="item.localLineTotal" badge-class="mr-2"/>
                        {{ $n(item.lineTotal, 'currency') }}
                      </div>

                      <template v-if="hasAddOns(item)">
                        <div>{{ $t('orders.details.addons') }}:</div>
                        <div class="pl-8">
                          <LocalePrice :price="item.addOns.localTotalPrice" badge-class="mr-2"/>
                          <span>{{ $n(item.addOns.totalPrice, 'currency') }}</span>
                        </div>
                      </template>
                      <div class="custom-top-border"></div>
                    </td>
                  </tr>
                  <tr class="order-addons">
                    <td colspan="4" style="border-top: 0; ">
                      <div v-for="product in item.addOns.products" :key="product.id" class="d-inline ma-3 text-center">
                        <v-badge :value="product.quantity" color="black" :content="product.quantity">
                          <VImg :src="`${product.image}&type=thumbnail`"
                                :lazy-src="`${product.image}&type=lazy`"
                                height="50px"
                                max-width="50px"
                                contain/>
                          {{ product.sku }}
                        </v-badge>
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-container>
                <v-row v-if="store" class="mt-4">
                  <v-col cols="3">
                    <k-display-group language-prefix="checkout.fields">
                      <k-text-display field="showroomLocation" class="mb-4">
                        <KAddressDisplay :loading="orderInfoLoading"
                                         :address="store.showroomAddress"/>
                      </k-text-display>
                      <k-text-display v-if="store.phoneNumber" width="20px" horizontal label="T">
                        {{ store.phoneNumber }}
                      </k-text-display>
                      <k-text-display v-if="store.email" width="20px" horizontal label="E">
                        {{ store.email }}
                      </k-text-display>
                    </k-display-group>
                  </v-col>
                  <v-col cols="3">
                    <k-display-group language-prefix="checkout.fields">
                      <k-text-display field="shippingAddress">
                        <KAddressDisplay :loading="orderInfoLoading"
                                         :address="store.shippingAddress"/>
                      </k-text-display>
                    </k-display-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col cols="12" md="4">
          <v-card :rounded="false" flat height="100%">
            <v-card-title>
              <k-subtitle>
                {{ $tc('checkout.orderCheckout') }}
              </k-subtitle>
            </v-card-title>
            <v-card-text>
              <v-form ref="submitForm">
                <k-field-group language-prefix="checkout.fields">
                  <KCheckbox dense v-model="submitOptions.install" field="install"/>
                  <KCheckbox dense v-model="submitOptions.financePlan" field="financePlan"/>
                  <KCheckbox dense v-model="submitOptions.terms" field="terms" required/>
                </k-field-group>
              </v-form>
            </v-card-text>
            <v-row justify="end">
              <VSpacer/>
              <v-col cols="auto">
                <div class="d-flex box--total">
                  <div class="d-inline-flex align-self-right">
                    <div class="mt-1 mr-4 font-weight-medium text-h3">{{ $t('checkout.total') }}:</div>
                    <i18n-n :tag="false"
                            :value="totalAmount"
                            :format="{ key: 'currency' }">
                      <template #currency="{currency}">
                        <div class="text-h1 font-weight-medium">{{ currency }}</div>
                      </template>
                      <template #group="{group}">
                        <div class="text-h1 font-weight-medium">{{ group }}</div>
                      </template>
                      <template #integer="{integer}">
                        <div class="text-h1 font-weight-medium">{{ integer }}</div>
                      </template>
                      <template #decimal="{decimal}">
                        <div class="text-h1 font-weight-medium">{{ decimal }}</div>
                      </template>
                      <template #fraction="{fraction}">
                        <div class="text-h3 font-weight-medium mt-1">{{ fraction }}</div>
                      </template>
                    </i18n-n>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-card-actions>
              <VSpacer/>
              <v-btn color="primary"
                     depressed
                     tile
                     @click="submitRequest"
                     :disabled="!canSubmit"
                     :loading="isPendingSubmit"
                     class="mr-2 mb-3">
                {{ $tc('checkout.requestOffer') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </k-crud-layout>
</template>

<script lang="js">
import { EventBus } from '@/application/EventBus.js';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { create, getStoreInformation, index } from '@/modules/shoppingCart/api';
import { mapActions, mapGetters } from 'vuex';
import KCheckbox from '@/components/crud/fields/KCheckbox.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import KAddressDisplay from '@/components/crud/display/KAddressDisplay.vue';
import KSubtitle from '@/components/layout/KSubtitle.vue';
import { SubmitOptions } from '@/application/models/SubmitOptions.js';
import { i18n } from '@/plugins';
import { StoreDetails } from '@/application/models/StoreDetails.js';
import LocalePrice from '@/components/LocalePrice.vue';
import LocalCurrencyFilter from '@/application/mixins/validLocalCurrency.js';

export default {
  name: 'OrderCheckout',
  components: {
    KAddressDisplay,
    KTextDisplay,
    KDisplayGroup,
    KFieldGroup,
    KCheckbox,
    KSubtitle,
    KCrudLayout,
    LocalePrice,
  },
  data() {
    return {
      tab: 0,
      store: null,
      shoppingCart: null,
      isPendingSubmit: false,
      items: [],
      addonsList: {},
      isAddonsLoading: [],
      mandatoryAddonsError: false,
      orderInfoLoading: false,
      submitOptions: new SubmitOptions(),
    };
  },
  watch: {
    async currentStoreId() {
      const { data: { data } } = await this.indexRequest();
      this.items = data;
    },
  },
  computed: {
    ...mapGetters('settings', { currentStoreId: 'getCurrentStoreId' }),
    crudHeaders() {
      return [
        {
          value: 'image',
          text: '',
          width: 132,
          sortable: false,
        },
        {
          value: 'name',
          text: this.$t('shoppingCart.headers.itemName'),
          cellClass: 'py-3',
        },
        {
          value: 'sku',
          text: this.$t('shoppingCart.headers.itemCode'),
          cellClass: 'py-3',
        },
        {
          value: 'quantity',
          text: this.$t('shoppingCart.headers.quantity'),
          cellClass: 'py-3',
        },
        {
          value: 'price',
          text: this.$t('shoppingCart.headers.salesPrice'),
          cellClass: 'py-3',
        },
        {
          value: 'linePrice',
          text: this.$t('shoppingCart.headers.linePrice'),
          cellClass: 'py-3',
        },
      ];
    },
    canSubmit() {
      return this.shoppingCart?.orderLines?.length > 0;
    },
    totalAmount() {
      return this.shoppingCart?.amount || 0;
    },
  },
  created() {
    this.getShoppingCart();
  },
  methods: {
    ...mapActions('shoppingCart', ['fetchAmountInCart']),
    async getShoppingCart() {
      const { data: { data } } = await this.indexRequest();
      this.items = data;
    },
    async submitRequest() {
      this.isPendingSubmit = true;
      try {
        await create(this.currentStoreId, this.submitOptions);
        await this.fetchAmountInCart(this.currentStoreId);
        // route to (empty) cart and show success message
        setTimeout(() => {
          EventBus.$emit('snackbar', {
            color: 'success',
            text: this.$t('shoppingCart.offerRequestedSuccess'),
          });
        }, 100);
        await this.$router.push({ name: 'shoppingCart' });
      } catch (e) {
        if (e?.response?.status !== 422) {
          // show general error
          EventBus.$emit('snackbar', {
            color: 'error',
            text: this.$t('errors.defaultErrorWrapper', [this.$t('errors.submitCart')]),
          });
        }
        this.$refs.submitForm.validate();
      }
      this.submitOptions = new SubmitOptions(); // reset
      await this.getShoppingCart();
      this.isPendingSubmit = false;
    },
    async indexRequest(...args) {
      try {
        const {
          data: {
            data,
            meta,
          },
        } = await index(this.currentStoreId, ...args);
        this.shoppingCart = {
          ...data,
          amount: data.amount,
          localAmount: LocalCurrencyFilter(data.localAmount),
          orderLines: data.orderLines.map(orderLine => ({
            ...orderLine,
            price: orderLine.price,
            lineTotal: orderLine.lineTotal,
            localLineTotal: orderLine.localLineTotal,
            localPrice: LocalCurrencyFilter(orderLine.localPrice),
            addOns: {
              ...orderLine['add-ons'],
              totalPrice: orderLine['add-ons'].totalPrice,
              localTotalPrice: LocalCurrencyFilter(orderLine['add-ons'].localTotalPrice),
              products: orderLine['add-ons'].products.map(product => ({
                ...product,
                price: product.price,
                localPrice: LocalCurrencyFilter(product.localPrice),
              })),
            },
          })),
        };

        return {
          data: {
            meta: meta,
            data: this.shoppingCart.orderLines,
          },
        };
      } catch (e) {
        EventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.defaultErrorWrapper', [this.$t('errors.retrieveCart')]),
        });
      }
    },
    hasAddOns(item) {
      return item.productHasAddons;
    },
    async getOrderInformation() {
      if (this.store === null) {
        this.orderInfoLoading = true;
        this.store = new StoreDetails();
        try {
          const storeRequest = await getStoreInformation(this.currentStoreId);
          this.store.mapResponse(storeRequest.data.data);
        } catch (err) {
          EventBus.$emit('snackbar', {
            color: 'error',
            text: i18n.t('errors.defaultErrorWrapper', [i18n.t('errors.fetchOrderInformation')]),
          });
        } finally {
          this.orderInfoLoading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tabs {
  width: max-content;
}

.box--total {
  height:       80px;
  box-sizing:   border-box;
  margin-right: 24px;
  margin-top:   24px;
}

::v-deep {
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    vertical-align: top;
    padding-top:    8px
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: inherit;
  }

  .v-data-table-header > tr > th {
    white-space: nowrap;
  }

  tbody td + td:not(.no-table-separator) {
    border-image:       linear-gradient(to bottom, rgba(0, 0, 0, 0) 11px, rgba(0, 0, 0, 0.25) 11px, rgba(0, 0, 0, 0.25) calc(28px), rgba(0, 0, 0, 0) calc(28px - 11px));
    border-image-slice: 1;
    border-left-style:  solid;
    border-left-width:  1px;
  }

  tbody tr:not(:first-child) td .custom-top-border {
    position: absolute;
    top: -1px;
    height: 1px;
    background: rgba(0, 0, 0, 0.12);
    left: -1px;
    right: -1px;
  }
}

</style>
